import Footer from "../components/Footer/Footer";
import Header from "../components/Header";

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full px-4 pt-6 lg:pt-12 md:w-4/12">
            <div className="relative flex flex-col w-full min-w-0 py-5 mb-8 break-words bg-white rounded-lg shadow-lg">
              <div className="flex flex-col items-center flex-auto px-4 text-center">
                <h6 className="text-xl font-semibold">Privacy Policy</h6>
                <p
                  className="mt-2 mb-4 text-gray-600 w-75"
                  style={{
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  We respect your privacy and are committed to protecting your
                  personal information. This privacy policy ("Policy") explains
                  how we collect, use, and protect your personal information
                  when you use our website rhaedmaker.com, operated by
                  RHeadMaker ("we," "us," or "our").
                </p>
              </div>
              <ul
                className="mx-auto w-75 "
                style={{
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                <li className="my-4">
                  1. Information We Collect We may collect the following types
                  of personal information when you use our website:
                  <div>
                    - Name and contact information, such as your email address
                    and phone number, which you may provide when you register
                    for an account, make a purchase, or contact us for customer
                    support.
                  </div>
                  <div>
                    - Billing and shipping information, such as your credit or
                    debit card number, billing address, and shipping address,
                    which you may provide when you make a purchase.
                  </div>
                  <div>
                    - Information about your use of our website, such as your IP
                    address, browser type, and operating system, which we may
                    automatically collect when you visit our website.
                  </div>
                </li>

                <li className="my-4">
                  2. How We Use Your Information We may use your personal
                  information for the following purposes:
                  <div>
                    - To provide and improve our website and services, including
                    to process and fulfill your orders, communicate with you
                    about your orders, and provide customer support.
                  </div>
                  <div>
                    - To personalize your experience on our website, such as by
                    displaying personalized content and advertising based on
                    your interests and browsing history.
                  </div>
                  <div>
                    - To send you promotional emails and other communications
                    about our products and services, unless you opt-out of
                    receiving such communications.
                  </div>
                  <div>
                    - To comply with legal obligations and protect our legal
                    rights and interests.
                  </div>
                </li>
                <li className="my-4">
                  3. Sharing Your Information We may share your personal
                  information with the following types of third parties:
                  <div>
                    - Service providers, such as payment processors and shipping
                    carriers, who help us provide and improve our website and
                    services.
                  </div>
                  <div>
                    - Third-party analytics and advertising providers who help
                    us analyze and optimize our website and advertising
                    campaigns.
                  </div>
                  <div>
                    - Law enforcement and other government entities, if required
                    by law or if we believe that such disclosure is necessary to
                    protect our legal rights and interests. We do not sell or
                    rent your personal information to third parties for their
                    own marketing purposes.
                  </div>
                </li>
                <li className="my-4">
                  4. Cookies and Other Technologies We may use cookies, web
                  beacons, and other tracking technologies to collect
                  information about your use of our website and to personalize
                  your experience. Cookies are small data files that are placed
                  on your device when you visit our website. Web beacons are
                  small image files that may be embedded in our website or
                  emails. These technologies may collect information such as
                  your IP address, browser type, and operating system, as well
                  as information about your browsing history on our website. You
                  can choose to disable cookies or other tracking technologies
                  in your browser settings, although this may affect the
                  functionality of our website.
                </li>
                <li className="my-4">
                  5. Data Retention and Security We retain your personal
                  information for as long as necessary to fulfill the purposes
                  for which it was collected, unless a longer retention period
                  is required or permitted by law. We use reasonable physical,
                  technical, and organizational security measures to protect
                  your personal information from unauthorized access, use, or
                  disclosure.
                </li>
                <li className="my-4">
                  6. Your Rights and Choices You have the right to access and
                  control your personal information in certain circumstances.
                  For example, you may have the right to:
                  <div>
                    - Access and receive a copy of your personal information
                    that we hold.
                  </div>
                  <div>- Update or correct your personal information.</div>
                  <div>
                    - Object to the processing of your personal information.
                  </div>
                  <div>
                    - Request that we delete your personal information. To
                    exercise your rights, please contact us at
                    rheadmaker19@gmail.com . Please note that we may need to
                    verify your identity before we can process your request.
                  </div>
                </li>
                <li className="my-4">
                  7. Children's Privacy Our website is not intended for children
                  under the age of 13. We do not knowingly collect personal
                  information from children under the age of 13. If you are a
                  parent or guardian and believe that your child has provided
                  personal information to us, please contact us at
                  rheadmaker19@gmail.com so that we can delete the information.
                </li>
                <li className="my-4">
                  8. Changes to this Policy We may update this Policy from time
                  to time to reflect changes in our privacy practices or
                  applicable laws. We will post the updated Policy on our
                  website and indicate the date of the latest revision. Your
                  continued use of our website after the updated Policy is
                  posted constitutes your acceptance of the updated Policy.
                </li>
                <li className="my-4">
                  9. Contact Us If you have any questions or concerns about this
                  Policy or our privacy practices, please contact us at
                  rheadmaker19@gmail.com.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default PrivacyPolicy;
