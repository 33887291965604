import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";

import axios from "axios";
import { DateTime } from "luxon";
import "react-toastify/dist/ReactToastify.css";

function Payments() {
  const [products, setProducts] = useState([]);
  const [edit, setEdit] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);

  const handlePageClick = (event) => {
    console.log(event);
    const _page = event.selected + 1;
    setPage(_page);
  };
  const fetchProducts = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL +
      "/admin/payment?page=" +
      page +
      "&limit=10&filter=" +
      JSON.stringify(filter),
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("user")).access_token
            }`,
        },
      }
    );
    setPageCount(Math.ceil(res.data.count / 10));
    setProducts(res.data.results);
  };
  useEffect(() => {
    fetchProducts();
  }, [filter, page]);

  const handleAddProduct = async () => {
    try {
      const formData = new FormData();

      Object.keys(edit).forEach((key) => {
        formData.append(key, edit[key]);
      });
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      await axios.patch(
        process.env.REACT_APP_BASE_URL + "/admin/payments/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("user")).access_token
              }`,
          },
        }
      );
      toast.success("Payment Updated Successfully");
      fetchProducts();
      return;
    } catch (e) {
      console.log(e);
      if (e.response.data) {
        toast.error(e.response.data.errors[0].msg);
      } else {
        toast.error(e.message);
      }
      return;
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const res = await axios.delete(
        process.env.REACT_APP_BASE_URL + "/admin/payment/" + id,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("user")).access_token
              }`,
          },
        }
      );
      toast.success("Payment Deleted Successfully");
      fetchProducts();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <main className="relative w-full pb-8">
      {/* <!--modal content--> */}

      {/* {modal && (
        <div
          id="edit_prod"
          className="absolute w-1/3 bg-white rounded-lg shadow left-1/3 top-36 dark:bg-gray-700 "
        >
          <div className="flex justify-end p-2">
            <button
              onClick={() => {
                setModal(false);
                setEdit(null);
                setMethod("Add");
              }}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="authentication-modal"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <form
            className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8"
            onSubmit={(e) => e.preventDefault()}
          >
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              {method} Payment
            </h3>
            <div>
              <label
                htmlFor="product"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Product Name
              </label>
              <input
                type="text"
                name="product"
                id="product"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                defaultValue={edit?.name}
                onChange={(e) => {
                  setEdit((prev) => ({ ...prev, name: e.target.value }));
                }}
              />
            </div>
            <div>
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Product Description
              </label>
              <input
                type="textarea"
                name="description"
                id="description"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                value={edit?.description}
                onChange={(e) => {
                  setEdit((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              />
            </div>
            <div>
              <label
                htmlFor="category"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Category
              </label>
              <select
                name="Category"
                id="Category"
                onChange={(e) => {
                  setEdit((prev) => ({ ...prev, category: e.target.value }));
                }}
                value={edit?.category}
              >
                <option value="">Select</option>
                {category.map((cat) => (
                  <option
                    key={cat._id}
                    value={cat.name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  >
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="subCategory"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                subCategory
              </label>
              <select
                name="subCategory"
                id="subCategory"
                onChange={(e) => {
                  setEdit((prev) => ({
                    ...prev,
                    subCategory: e.target.value,
                  }));

                  edit.subCategory = e.target.value;
                }}
                value={edit?.subCategory}
              >
                <option value="">Select</option>
                {subCategory.map((cat) => (
                  <option
                    key={cat._id}
                    value={cat.name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  >
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="price"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Price
              </label>
              <input
                type="text"
                name="price"
                id="price"
                defaultValue={edit?.price}
                onChange={(e) => {
                  setEdit((prev) => ({ ...prev, price: e.target.value }));
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              />
            </div>
            <div>
              <label
                htmlFor="displayPrice"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Display Price
              </label>
              <input
                type="text"
                name="displayPrice"
                id="displayPrice"
                defaultValue={edit?.displayPrice}
                onChange={(e) => {
                  setEdit((prev) => ({
                    ...prev,
                    displayPrice: e.target.value,
                  }));
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              />
            </div>
            <div>
              <label
                htmlFor="shippingPrice"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Shipping Price
              </label>
              <input
                type="text"
                name="shippingPrice"
                id="shippingPrice"
                defaultValue={edit?.shippingPrice}
                onChange={(e) => {
                  setEdit((prev) => ({
                    ...prev,
                    shippingPrice: e.target.value,
                  }));
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              />
            </div>

            <div>
              <label
                htmlFor="image1"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Image 1
              </label>
              <input
                multiple
                type="file"
                name="myImage"
                onChange={(e) => {
                  // const check = dataURItoBlob(e.target.files[0]);
                  console.log(e.target.files[0]);
                  edit.image_1 = e.target.files[0];
                }}
              />
            </div>
            <div>
              <label
                htmlFor="image2"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Image 2
              </label>
              <input
                type="file"
                name="myImage"
                onChange={(e) => {
                  // const check = dataURItoBlob(e.target.files[0]);
                  console.log(e.target.files[0]);
                  edit.image_2 = e.target.files[0];
                }}
              />
            </div>
            <div>
              <label
                htmlFor="image3"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Image 3
              </label>
              <input
                type="file"
                name="myImage"
                onChange={(e) => {
                  // const check = dataURItoBlob(e.target.files[0]);
                  console.log(e.target.files[0]);
                  edit.image_3 = e.target.files[0];
                }}
              />
            </div>
            <div>
              <label
                htmlFor="image4"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Image 4
              </label>
              <input
                type="file"
                name="myImage"
                onChange={(e) => {
                  // const check = dataURItoBlob(e.target.files[0]);
                  console.log(e.target.files[0]);
                  edit.image_4 = e.target.files[0];
                }}
              />
            </div>

            <button
              onClick={(e) => {
                e.preventDefault();
                handleAddProduct();
              }}
              className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Save Changes
            </button>
          </form>
        </div>
      )} */}

      <div className="flex items-center justify-between px-10 py-7">
        <div>
          <h1 className="text-2xl font-semibold leading-relaxed text-gray-800">
            Payments
          </h1>
          <p className="text-sm font-medium text-gray-500">
            Let&apos;s grow your business! Create your product and upload here
          </p>
        </div>
      </div>
      {/* show two buttons for filter : 1. PENDING, 2. VERYFIED */}
      <div className="p-3">
        <label htmlFor="statusFilter">Status : </label>
        <select
          name="statusFilter"
          className="mx-2 my-1"
          onChange={(e) => {
            if (e.target.value === "All") setFilter({});
            else setFilter({ paymentStatus: e.target.value });
          }}
          value={filter.paymentStatus}
        >
          <option value="All">All</option>
          <option value="PENDING">PENDING</option>
          <option value="VERIFIED">VERIFIED</option>
        </select>
      </div>

      <table className="w-full border-b border-gray-200">
        <thead>
          <tr className="text-sm font-medium text-gray-700 border-b border-gray-200">
            <td className="pl-10">
              <span> {"  "}</span>
              <div className="flex items-center gap-x-4">
                <span>Name</span>
              </div>
            </td>
            <td className="px-4 py-4 text-center">Email</td>
            <td className="px-4 py-4 text-center">Transaction ID</td>
            <td className="px-4 py-4 text-center">Payment Status</td>
            <td className="px-4 py-4 text-center">Date</td>
          </tr>
        </thead>
        <tbody className="w-fit">
          {products.length > 0 &&
            products?.map((prod) => {
              return (
                <tr
                  key={prod._id}
                  //loop for diff products
                  className="transition-colors hover:bg-gray-100 group"
                >

                  <td className="flex items-center py-4 pl-10 gap-x-4">
                    {prod.payerName}
                  </td>
                  <td className="font-medium text-center">
                    {" "}
                    {prod.payerEmail}{" "}
                  </td>
                  <td className="font-medium text-center">
                    {" "}
                    {prod.paymentId}{" "}
                  </td>
                  <td className="font-medium text-center">
                    {" "}
                    {prod.paymentStatus}{" "}
                  </td>
                  <td className="font-medium text-center">
                    {" "}
                    {DateTime.fromISO(prod.createdAt).toFormat("dd LLL yyyy")}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="flex justify-center pt-8 gap-x-2 ">
        <ReactPaginate
          breakLabel="..."
          containerClassName="flex justify-between list-none pointer items-center h-10 space-x-3"
          activeLinkClassName="bg-blue-600 text-white"
          pageLinkClassName="p-2 border-2 rounded-sm text-primary border-blue-600 hover:bg-blue-600 hover:text-white"
          pageRangeDisplayed={5}
          renderOnZeroPageCount={null}
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          previousLinkClassName={"font-bold"}
          nextLinkClassName={"font-bold"}
          disabledClassName={"text-gray-500 cursor-not-allowed"}
          activeClassName={"text-white "}
        />
      </div>
    </main>
  );
}
export default Payments;
