import styles from "./Banner.module.css";

const Banner = () => {
  return (
    <div className={styles.frameDiv}>
      <img
        className={styles.f8140faec46017976ce96ee20decfeIcon}
        alt=""
        src="/banner.png"
      />
    </div>
  );
};

export default Banner;
