import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Product.module.css";

const Product = ({ data, addToCart }) => {
  return (
    <>
      <div className={[styles.groupDiv]}>
        <img
          className={styles.rectangleIcon1}
          width="100%"
          src={
            process.env.REACT_APP_IMAGE_URL +
            "/static/product_images/" +
            data?._id +
            "/" +
            data?.image?.[0]
          }
          alt="product"
        />
        <Link to={"product/" + data._id}>
          <div className={styles.grayCapOfCottonFabric}>
            <h2>
              {data.name}
            </h2>
          </div>
        </Link>
        <Button
          variant="outline-primary"
          style={{
            fontWeight: "bold",
            fontSize: "19px",
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
          onClick={() => addToCart(data)}
        >
          +
        </Button>
        <div className={styles.div}>₹ {data.price}</div>
        <div className={styles.saleDiv}>sale</div>
      </div>
      {/* <Col>
        <Row></Row>
      </Col> */}
    </>
  );
};

export default Product;
