import Header from "../components/Header";
import style from "./Login/index.module.css";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  if (!params.token) {
    navigate("/login");
  }

  const handleSubmit = async (e) => {
      e.preventDefault();

      if (password !== cpassword) {
        toast.error("Passwords do not match");
        return;
      }

    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + "/auth/reset-password",
        {
          token: params.token,
          password,
        }
      );
      console.log(res.data);
      toast.success("Password reset successfully");
      navigate("/login");
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      if (e.response.data) {
        toast.error(e.response.data.errors[0]?.msg);
      } else {
        toast.error(e.message);
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      <Header />
      <div className={style.login}>
        <div className={style.login__container}>
          <h3>Reset Password</h3>
          <form
            className={style.login__form}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <label className="fs-6">New Password</label>
            <input
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label className="fs-6">Confirm Password</label>
            <input
              type="password"
              value={cpassword}
              onChange={(e) => setCpassword(e.target.value)}
            />
            <button
              type="submit"
              className={style.login__signInButton}
              onClick={(e) => {
                setIsLoading(true);
                handleSubmit(e);
              }}
            >
              {isLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default ResetPassword;
