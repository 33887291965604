import { Accordion, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={styles.container}>
      <Row className="items-center justify-center mb-5">
        <Col>
          <Row className="mb-2 font-bold">
            <h4>FAQs</h4>
          </Row>
        </Col>

        <Accordion defaultActiveKey={"0"} className="!list-disc">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Care Guide</Accordion.Header>
            <Accordion.Body>
              <ul className="visible space-y-1 text-gray-500 dark:text-gray-400">
                <li>- Always use cold water.</li>
                <li>- Do not machine wash.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>How do I return or exchange</Accordion.Header>
            <Accordion.Body>
              <ul className="visible space-y-1 text-gray-500 dark:text-gray-400">
                <li>
                  - You can email us at: RHeadMaker19@gmail.com to initiate the
                  return/exchange
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Are there any charges for return and exchange?
            </Accordion.Header>
            <Accordion.Body>
              <ul className="visible space-y-1 text-gray-500 dark:text-gray-400">
                <li>- No, all return and exchange are free of cost</li>
                <li>
                  - All items are purchased under discount are not eligible for
                  any return or exchange, all sales are final
                </li>
                <li>
                  - International orders are not eligible for any return or
                  exchange
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Why will my return or exchange is rejected?
            </Accordion.Header>
            <Accordion.Body>
              <ul className="visible space-y-1 text-gray-500 dark:text-gray-400">
                <li>
                  - Item received has not been sent back with all its original
                  packaging and perfect condition.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>How soon will I get my refund?</Accordion.Header>
            <Accordion.Body>
              <ul className="space-y-1 text-gray-500 visible  !list-disc  dark:text-gray-400">
                <li>
                  - It will take up-to 3 to 5 business days for refund to
                  reflect in your account, after the return item has been
                  delivered and inspected by us.
                </li>
              </ul>
              <p className="">
                * Note: You can register return and exchange for your order
                within 7 days of receipt of your shipment.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row className="justify-center ">
        <Col>
          <Row className="mb-2 font-bold">
            <h4>Contact Details</h4>
          </Row>
          <span>
            <Row>
              <h4>
                Sparash recidency, Near Ashwini Bari, Opposite Prashant park
                fatehpura paldi
              </h4>
            </Row>
            <Row>
              <h4>Ahmedabad Gujarat - 380007</h4>
            </Row>
            <Row>
              <h4>Contact - 9328790227</h4>
            </Row>
            <Row>
              <h4>
                Email -{" "}
                <a href="mailto:rheadmaker19@gmail.com">
                  rheadmaker19@gmail.com{" "}
                </a>
              </h4>
            </Row>
          </span>
        </Col>
        <Col>
          <Row className="mb-2 font-bold">
            <Link to={"/terms-and-conditions"}>Terms & Conditions</Link>
          </Row>
          <Row className="mb-2 font-bold">
            <Link to={"/privacy-policy"}>Privacy Policy</Link>
          </Row>
          <Row className="mb-2">
            <h4 className="mb-2 font-bold">Shipping Information</h4>
            <h3>
              Ahmedabad: Enjoy next-day or same-day delivery for all orders
              placed before 9 am.
              <br />
              Rest of India: 7-10 working days
              <br />
              FREE SHIPPING ON ALL ORDERS
            </h3>
          </Row>
          <Row className="mb-2">
            <h4 className="mb-2 font-bold">About Us</h4>
            <h3>
              Rheadmaker is a part of Nemi Parshwa Corporation
            </h3>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
