
import axios from "axios";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const OrderList = () => {
  const [userList, setUsersList] = useState([]);
  const [edit, setEdit] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const status = [
    { name: "Pending", value: "pending" },
    { name: "Processing", value: "processing" },
    { name: "Delivered", value: "delivered" },
    { name: "Cancelled", value: "cancelled" },
  ];

  useEffect(() => {
    fecthUsers();
  }, [filter, page]);
  //handle Pagination Click
  const handlePageClick = (event) => {
    console.log(event);
    const _page = event.selected + 1;
    setPage(_page);
  };

  const fecthUsers = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_BASE_URL +
          "/order?page=" +
          page +
          "&limit=10&filter=" +
          JSON.stringify(filter),
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(sessionStorage.getItem("user")).access_token
            }`,
          },
        }
      );
      setPageCount(Math.ceil(res.data.count / 10));

      setUsersList(res.data.results);
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateOrderStatus = async (e, _id) => {
    try {
      const res = await axios.patch(
        process.env.REACT_APP_BASE_URL + "/order/" + _id,
        {
          status: e.target.value,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(sessionStorage.getItem("user")).access_token
            }`,
          },
        }
      );
      toast.success("Order Status Updated");
      fecthUsers();
    } catch (e) {
      console.log(e);
      if (e.response.data) {
        toast.error(e.response.data.errors[0].msg);
      } else {
        toast.error(e.message);
      }
    }
  };

  return (
    <main className="relative w-full pb-8">
      <div className="flex items-center justify-between px-10 py-7">
        <div>
          <h1 className="text-2xl font-semibold leading-relaxed text-gray-800">
            Orders
          </h1>
          <p className="text-sm font-medium text-gray-500">
            Let&apos;s grow your business! Manage your Orders here
          </p>
        </div>
        <div className="p-3">
          <label htmlFor="categoryFilter">Status : </label>
          <select
            name="categoryFilter"
            className="mx-2 my-1"
            onChange={(e) => {
              setFilter({ status: e.target.value });
            }}
            value={filter.category}
          >
            <option value="">All</option>
            {status.map((status) => {
              return <option value={status.value}>{status.name}</option>;
            })}
          </select>
        </div>
      </div>

      <table className="w-full border-t border-b border-gray-200">
        <thead>
          <tr className="text-sm font-medium text-gray-700 border-b border-gray-200">
            <td className="pl-10">
              <span> {"  "}</span>
              <div className="flex items-center">
                <span>User Name</span>
              </div>
            </td>
            <td className="py-4 text-center">Email</td>
            <td className="py-4 text-center truncate">Shipping Address</td>
            <td className="py-4 text-center">Product</td>
            <td className="py-4 pl-4 text-center">Amount</td>
            <td className="py-4 pl-4 text-center">Payment</td>
            <td className="py-4 pl-4 text-center">Order Date</td>
            <td className="py-4 pl-4 text-center">Status</td>
          </tr>
        </thead>
        <tbody className="w-fit">
          {userList?.length > 0 &&
            userList?.map((user) => {
              return (
                <tr
                  key={user._id}
                  //loop for diff users
                  className="transition-colors hover:bg-gray-100 group"
                >
                  <td className="flex items-center py-4 pl-8">
                    <div>
                      <p className="text-lg font-semibold text-gray-700">
                        {user.name}
                      </p>
                    </div>
                  </td>
                  <td className="font-medium text-center">
                    <div className="font-medium text-gray-400">
                      {" "}
                      {user.email}{" "}
                    </div>{" "}
                  </td>
                  <td
                    className="w-6 font-medium text-center truncate"
                    title={
                      user.address.address +
                      ", " +
                      user.address.city +
                      ", " +
                      user.address.pincode
                    }
                  >
                    <p className="truncate">
                      {" "}
                      {user.address.address}, {user.address.city},
                      {user.address.pincode}{" "}
                    </p>
                  </td>
                  <td className="font-medium text-center">
                    {" "}
                    <Link
                      to={"/order/" + user._id}
                      className="p-1 text-white bg-blue-500 rounded-md outline outline-1 outline-blue-500 hover:bg-blue-700"
                    >
                      See Order{" "}
                    </Link>
                  </td>
                  <td className="font-medium text-center"> {user.total}</td>
                  <td className="font-medium text-center">
                    {" "}
                    {user.paymentMethod} {
                      user.paymentMethod === "ONLINE" && (user?.transactionId?.paymentStatus === "PENDING" ? (
                        <span className="text-red-500"> ({
                          user?.transactionId?.paymentStatus
                        })</span>
                      ) : (
                        <span className="text-green-500"> ({
                          user?.transactionId?.paymentStatus
                        })</span>
                      ))
                    }
                  </td>

                  <td className="font-medium text-center">
                    {" "}
                    {DateTime.fromISO(user.createdAt).toFormat("dd LLL yyyy")}
                  </td>
                  <td className="pr-2">
                    <span className="inline-block w-20">
                      <select
                        value={user.status}
                        onChange={(e) => updateOrderStatus(e, user._id)}
                      >
                        <option value="pending">Pending</option>
                        <option value="processing">Processing</option>
                        <option value="delivered">Delivered</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {userList?.length > 0 && (
        <div className="flex justify-center pt-8 gap-x-2 ">
          <ReactPaginate
            breakLabel="..."
            containerClassName="flex justify-between list-none pointer items-center h-10 space-x-3"
            activeLinkClassName="bg-blue-600 text-white"
            pageLinkClassName="p-2 border-2 rounded-sm text-primary border-blue-600 hover:bg-blue-600 hover:text-white"
            pageRangeDisplayed={5}
            renderOnZeroPageCount={null}
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            previousLinkClassName={"font-bold"}
            nextLinkClassName={"font-bold"}
            disabledClassName={"text-gray-500 cursor-not-allowed"}
            activeClassName={"text-white "}
          />
        </div>
      )}
    </main>
  );
};

export default OrderList;
