import Footer from "../components/Footer/Footer";
import Header from "../components/Header";

function TermsAndConditions() {
  return (
    <>
      <Header />
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full px-4 pt-6 lg:pt-12 md:w-4/12">
            <div className="relative flex flex-col w-full min-w-0 py-5 mb-8 break-words bg-white rounded-lg shadow-lg">
              <div className="items-center flex-auto px-4 text-center">
                <h6 className="text-xl font-semibold">Terms & Conditions</h6>
                <p className="mt-2 mb-4 text-gray-600">
                  Please read these Terms and Conditions carefully before using
                  the https://www.rheadmaker.com/ website operated by
                  RHeadMaker.
                </p>
              </div>
              <ul
                className="mx-auto w-75 "
                style={{
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                <li className="my-4">
                  1. Use of Website Our website is provided solely for your
                  personal, non-commercial use. You may not use our website for
                  any other purpose, including any commercial purpose, without
                  our express prior written consent.
                </li>
                <li className="my-4">
                  2. Intellectual Property Rights All content on our website,
                  including but not limited to text, graphics, logos, images,
                  and software, is our property or the property of our licensors
                  and is protected by copyright, trademark, and other
                  intellectual property laws. You may not use, reproduce,
                  modify, or distribute any content from our website without our
                  express prior written consent.
                </li>
                <li className="my-4">
                  3. Product Information and Pricing We make every effort to
                  ensure that the information on our website is accurate and
                  up-to-date, but we do not guarantee the accuracy or
                  completeness of such information. We reserve the right to
                  change product information, pricing, and availability at any
                  time without notice.
                </li>
                <li className="my-4">
                  4. Orders and Payment By placing an order on our website, you
                  agree to pay the full price of the order, including any
                  applicable taxes and shipping fees. We reserve the right to
                  reject or cancel any order for any reason, including but not
                  limited to errors in pricing or product information. Payment
                  for orders must be made by a valid credit or debit card or
                  other payment method accepted by us.
                </li>
                <li className="my-4">
                  5. Shipping and Delivery We will make every effort to ship
                  your order promptly and to ensure that it is delivered to you
                  in a timely manner. However, we are not responsible for any
                  delays or damages caused by shipping carriers or other third
                  parties.
                </li>
                <li className="my-4">
                  6. Returns and Refunds If you are not satisfied with your
                  purchase, you may return it to us within 7 days of receipt for
                  a refund or exchange, subject to our return policy. We reserve
                  the right to refuse any returns or exchanges that do not
                  comply with our policy.
                </li>
                <li className="my-4">
                  7. Privacy Policy We are committed to protecting your privacy.
                  Our Privacy Policy explains how we collect, use, and protect
                  your personal information. By using our website, you agree to
                  be bound by our Privacy Policy.
                </li>
                <li className="my-4">
                  8. Limitation of Liability IN NO EVENT SHALL WE BE LIABLE FOR
                  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
                  DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OUR
                  WEBSITE OR THE PRODUCTS SOLD ON OUR WEBSITE, WHETHER BASED ON
                  CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN IF WE
                  HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </li>
                <li className="my-4">
                  9. Indemnification You agree to indemnify, defend, and hold us
                  harmless from and against any and all claims, damages, costs,
                  and expenses, including attorneys' fees, arising from or
                  related to your use of our website or your breach of these
                  Terms.
                </li>
                <li className="my-4">
                  10. Governing Law and Jurisdiction These Terms and any
                  disputes arising from or related to these Terms or our website
                  shall be governed by and construed in accordance with the laws
                  of India, without regard to its conflicts of law principles.
                  Any legal action or proceeding arising from or related to
                  these Terms or our website shall be brought exclusively in the
                  federal or state courts located in India, and you hereby
                  consent to the personal jurisdiction of such courts.
                </li>
                <li className="my-4">
                  11. Changes to Terms and Conditions We reserve the right to
                  change these Terms at any time without notice. Your continued
                  use of our website following any changes to these Terms
                  constitutes your acceptance of the revised Terms.
                </li>
                <li className="my-4">
                  12. Entire Agreement These Terms constitute the entire
                  agreement between you and us regarding the use of our website
                  and supersede all prior agreements and understandings, whether
                  written or oral, regarding such use.
                </li>
                <li className="my-4">
                  13. Waiver and Severability Our failure to enforce any
                  provision of these Terms shall not be deemed a waiver of such
                  provision or of our right to enforce such provision. If any
                  provision of these Terms is held by a court or other tribunal
                  of competent jurisdiction to be invalid, illegal, or
                  unenforceable for any reason, such provision shall be
                  eliminated or limited to the minimum extent such that the
                  remaining provisions of these Terms will continue in full
                  force and effect.
                </li>
                <li className="my-4">
                  14. Contact Us If you have any questions or comments about
                  these Terms or our website, please contact us at{" "}
                  <a href="mailto:rheadmaker19@gmail.com">
                    rheadmaker19@gmail.com
                  </a>{" "}
                  .
                </li>
                Thank you for using our website! Waiver and Severability Our
                failure to enforce any provision of these Terms shall not be
                deemed a waiver of such provision or of our right to enforce
                such provision. If any provision of these Terms is held by a
                court or other tribunal of competent jurisdiction to be invalid,
                illegal, or unenforceable for any reason, such provision shall
                be eliminated or limited to the minimum extent such that the
                remaining provisions of these Terms will continue in full force
                and effect. Contact Us If you have any questions or comments
                about these Terms or our website, please contact us at{" "}
                <a href="mailto:rheadmaker19@gmail.com">
                  rheadmaker19@gmail.com
                </a>
                . Thank you for using our website!
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default TermsAndConditions;
