import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import styles from "./index.module.css";

function ProductInfo() {
  const id = useParams();
  const ref = useRef(null);
  const [product, setProduct] = useState({});
  const [bigImage, setBigImage] = useState(0);
  const fetchProduct = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL + "/product/" + id.id
    );
    setProduct(res.data);
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const changeCount = (e) => {
    setProduct({ ...product, count: parseInt(e.target.value) });
  };

  async function addToCart() {
    if (!product.count) {
      toast.error("Please Select Quantity");
      return;
    }
    let tempCart = JSON.parse(sessionStorage.getItem("cart"));
    if (!tempCart) {
      toast.success("Added to Cart");

      sessionStorage.setItem("cart", JSON.stringify([{ ...product }]));
      return;
    }

    const exists = tempCart.find((item) => item._id === product._id);
    if (exists) {
      tempCart = tempCart.map((item) => {
        if (item._id === product._id) {
          return { ...item, count: item.count + product.count };
        }
        return item;
      });
    } else {
      tempCart.push({ ...product });
    }

    sessionStorage.setItem("cart", JSON.stringify(tempCart));
    toast.success("Added to Cart");

    return;
  }


  const handleSwipe = useMemo(
    (e) => {
      if (e === 'left') {
        if (bigImage === product?.image?.length - 1) {
          setBigImage(0)
          return
        } else {
          setBigImage(
            prev => prev + 1
          )
          return
        }
      }
      if (e === 'right') {
        if (bigImage === 0) {
          setBigImage(
            prev => prev = product?.image?.length - 1
          )
          return
        } else {
          setBigImage(
            prev => prev - 1
          )
          return
        }
      }
    }, [bigImage, product?.image?.length]
  )

  const settings = useMemo(
    () => ({
      ref: ref,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      onSwipe: handleSwipe,
      afterChange:
        (current) => {
          setBigImage(current)
        },
      arrows: false,
      waitForAnimate: false
      // autoplay: true,
      // autoplaySpeed: 3000,
    }), [handleSwipe]
  )



  if (!product._id) {
    return <h3>Page Loading</h3>;
  }

  return (
    <Container>
      <Row
        style={{
          marginInline: "auto",
        }}
      >
        <Row
          sm={3}
          style={{
            margin: "auto",
            backgroundColor: "whitesmoke",
            borderRadius: 10,
          }}
        >
          <Col sm={10}>
            <Row>
              <h1 className={styles.productTitle}>{product.name}</h1>
            </Row>
            <Row>

              <Slider {...settings}

              >
                {product.image.map((item, i) => {
                  return (
                    <div key={i}>
                      <img
                        style={{
                          margin: 10,
                          maxHeight: "60vh",
                          aspectRatio: "1/1",
                          marginInline: "auto",
                          // center in parent
                          display: "block",
                          objectFit: "contain",
                        }}
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/static/product_images/" +
                          product._id +
                          "/" +
                          item
                        }
                        alt=""
                      />
                    </div>
                  );
                })}
              </Slider>
            </Row>
          </Col>
          <Col
            sm={2}

          >
            <div className={styles.imageContainer + " flex-row flex-sm-column "}>
              {product.image.map((item, i) => {
                return (
                  <img
                    key={i}
                    onClick={() => {
                      setBigImage(i);
                      ref.current.slickGoTo(i)
                      return;
                    }}
                    style={{
                      aspectRatio: "1/1",
                    }}
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      "/static/product_images/" +
                      product._id +
                      "/" +
                      item
                    }
                    alt=""
                    className={
                      [
                        styles.previewImage + " ",
                        i === bigImage
                          ?
                          // add bootstrap outline class
                          "img-thumbnail border border-primary p-1"
                          // make max width 75%
                          : ""
                      ]
                    }
                  />
                );
              })}
            </div>
          </Col>

        </Row>

        <Row className={styles.productInfo}>
          <Col sm={2} md={6}>
            <span
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                color: "red",
              }}
            >
              <h3>Price : ₹ {product.price} </h3>
              {product.price !== product.displayPrice && (
                <h6>
                  <s> ₹ {product.displayPrice}</s>
                </h6>
              )}{" "}
            </span>
            <div className={styles.productDescription}>
              <h3>Description</h3>
              <p>{product.description}</p>
            </div>
          </Col>
          <Col sm={2} md={6}>
            <Row>
              <div className={styles.checkoutBox}>
                <h3>Checkout</h3>
                <div className={styles.checkoutBoxContent}>
                  <span></span>

                  <Col>
                    <h5>Quantity: </h5>
                    <select
                      name="productCount"
                      value={product?.count}
                      onChange={changeCount}
                    >
                      <option>Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </Col>
                  {/* Buy now button */}
                  <button
                    className={styles.buyNowBtn}
                    onClick={() => {
                      addToCart();
                    }}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}
export default ProductInfo;
